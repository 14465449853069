import React, { useState } from "react"

import { graphql, navigate } from "gatsby"
import moment from "moment"

import CustomButton from "../components/button"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import BlogList from "../components/blogList"
import ConnectIcon from "../assets/connect-icon.svg"
import "./blogPost.css"
import { useStoryblokState } from "gatsby-source-storyblok"

// import RenderRichText from "../components/RenderRichText"
import { render } from "storyblok-rich-text-react-renderer"

const content =
  "TAG Finance and Loans Pty Ltd ACN 609 906 863 Credit Representative Number 483873 have access to a panel of lenders via National Mortgage Brokers Pty Ltd ACN 093 874 376 / Australian Credit Licence 391209, which is a fully-owned subsidiary of Liberty Financial Pty Ltd ACN 077 248 983 / Australian Credit Licence 286596. Our brokers have access to products including those from Liberty Financial."

const BlogPost = ({ data }) => {
  const [amount, setAmount] = useState(3)

  let story = data.storyblokEntry
  story = useStoryblokState(story)
  // const components = story.content.content.content.map(blok => (
  //   <StoryblokComponent blok={blok} key={blok._uid} />
  // ));

  return (
    <Layout subfooter={content}>
      <SEO title="Insights" />
      <div className="blog-post-container">
        <Banner />
        <div
          style={{
            position: "relative",
            top: "9vw",
            "z-index": 1,
            paddingBottom: "10%",
          }}
        >
          <div className="blog-post-body">
            <h4 className="blog-post--header">
              {story.content.category[0].name} |{" "}
              {moment(story.first_published_at).format("Do MMMM YYYY")}
            </h4>
            <h3 className="blog-post--title">{story.name}</h3>
            <div className="blog-post-content">
              {render(story.content.content)}
            </div>
          </div>
          <h3 className="blog-post-card-header">Related Insights</h3>
          <BlogList
            blogPreviews={data.allStoryblokEntry.nodes}
            amount={amount}
          />
          {data.allStoryblokEntry.length > amount && (
            <div className="blog-button">
              <CustomButton
                variant="secondary"
                className="blog-button-content"
                onClick={() => setAmount(amount + 3)}
              >
                View more
              </CustomButton>
            </div>
          )}
          <div className="blog-post--banner">
            <div className="blog-post-banner--circle-image">
              <ConnectIcon className="blog-post-banner--icon" />
            </div>
            <div className="blog-post-banner--header">
              Connect with Compare n Save
            </div>
            <div className="blog-post-banner--button">
              <CustomButton
                variant="secondary"
                className="blog-post-banner--content"
                onClick={() => navigate("/contact")}
              >
                Request a call back
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      name
      content
      first_published_at
    }
    allStoryblokEntry(
      filter: { field_component: { eq: "post" } }
      sort: { fields: [first_published_at], order: DESC }
    ) {
      nodes {
        id
        name
        full_slug
        content
        first_published_at
      }
    }
  }
`
