import React from "react"
import { navigate } from "gatsby"
import moment from "moment"

import { Card } from "react-bootstrap"
import "./blogList.css"

const BlogList = ({ blogPreviews, amount }) => {

  return (
    <div className={amount == 4 ? "blog-previews-4" : "blog-previews-12"}>
      {blogPreviews
        .slice(0, amount)
        .map(
          ({
            name,
            content: contentString,
            id,
            full_slug,
            first_published_at,
          }) => {
            const parsedContent = JSON.parse(contentString) // Parse the JSON string to an object
            const category = parsedContent.category
              ? parsedContent.category[0].name
              : null // Access the category name
            // Assuming parsedContent is the parsed JSON object from Storyblok
            const excerptContent = parsedContent.excerpt.content // Access the excerpt content

            let newExcerpt = ""

            if (excerptContent) {
              newExcerpt = excerptContent
                .map(paragraph => {
                  if (paragraph.content) {
                    return paragraph.content
                      .map(textElement => {
                        if (textElement.type === "text") {
                          return textElement.text
                        }
                        return ""
                      })
                      .join(" ")
                  }
                  return ""
                })
                .join(" ")
                .split("Continue reading")[0]
            }
            const truncateExcerptWithoutCuttingWords = (
              contentString,
              maxLengths
            ) => {
              const windowWidth =
                typeof window !== "undefined" ? window.innerWidth : 0
              let maxLength

              if (windowWidth <= 576) {
                maxLength = maxLengths.xs
              } else if (windowWidth <= 768) {
                maxLength = maxLengths.sm
              } else if (windowWidth <= 1024) {
                maxLength = maxLengths.md
              } else if (windowWidth <= 1440) {
                maxLength = maxLengths.lg
              } else if (windowWidth <= 1800) {
                maxLength = maxLengths.xl
              } else {
                maxLength = maxLengths.xxl
              }

              if (contentString.length <= maxLength) {
                return contentString
              }

              const lastSpaceIndex = contentString.lastIndexOf(" ", maxLength)
              return contentString.substring(0, lastSpaceIndex) + " ..."
            }
            return (
              <div key={id}>
                <Card
                  className="blog-card"
                  onClick={() => navigate(`/${full_slug}`)}
                >
                  <div className="blog-header">
                    {category} |{" "}
                    {moment(first_published_at).format("Do MMMM YYYY")}
                  </div>

                  <div className="blog-title">{name}</div>

                  <Card.Body
                    className="blog-excerpt"
                    dangerouslySetInnerHTML={{
                      __html: truncateExcerptWithoutCuttingWords(newExcerpt, {
                        xs: 100,
                        sm: 170,
                        md: 250,
                        lg: 200,
                        xl: 200,
                        xxl: 250,
                      }),
                    }}
                  ></Card.Body>
                </Card>
              </div>
            )
          }
        )}
    </div>
  )
}

export default BlogList
